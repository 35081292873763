






































































































































import {
  computed,
  defineComponent,
  PropType,
  toRef,
} from '@nuxtjs/composition-api';
import Nullable from '@cellar-services/components/atoms/Nullable.vue';
import { SfImage } from '@storefront-ui/vue';
import type { AlgoliaCellarItemInterface, ValuationInterface } from '@cellar-services/types';
import { useCellarItems } from '@cellar-services/stores/cellar-items';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'SellingOptions',
  components: {
    Nullable,
    SfImage,
  },
  props: {
    item: {
      type: Object as PropType<AlgoliaCellarItemInterface>,
      required: true,
    },
    valuation: {
      type: Object as PropType<ValuationInterface>,
      required: true,
    },
  },
  setup(props) {
    const item = toRef(props, 'item');
    const store = useCellarItems();
    const { findFullItem } = storeToRefs(store);

    const cellarItem = computed(() => findFullItem.value(item.value.objectID));

    return {
      cellarItem,
    };
  }
});
